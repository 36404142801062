import React from 'react';
import './Gallery.css';
import gallery1 from '../../assets/gallery1.jpeg';
import gallery2 from '../../assets/gallery2.png';
import gallery3 from '../../assets/gallery3.jpeg';
import gallery4 from '../../assets/gallery4.png';
import gallery5 from '../../assets/gallery5.png';
import gallery6 from '../../assets/gallery6.png';
import gallery7 from '../../assets/gallery7.png';
import gallery8 from '../../assets/gallery8.png';
import gallery9 from '../../assets/gallery9.png';

const Gallery = () => (
  // <>
  // <div className="events-banner-image" />
  <div className="gallery">
    <h2>Brands</h2>
    <div className="gallery-heading">Terribly Tiny Tales is a celebrated Indian storytelling and microfiction platform. As a contributing writer. I told stories for over 30 brands: </div>
    <div className="gallery-body">
      <div className="gallery-section">
        <div className="image-container"><img className="gallery-image" src={gallery1} alt="Mia by Tanishq" /></div>
        <div className="gallery-content">
          Client: Mia by Tanishq
          {' '}
          <br />
          With Mia by Tanishq we were celebrating the hopeless romantic in each of us.
          Crafted for Indian Working Women, this jewelry brand offers contemporary
          designs and we tried to encapture the modern love story to make the perfect gifts.
        </div>
      </div>
      <div className="gallery-section">
        <div className="image-container"><img className="gallery-image" src={gallery2} alt="UCB" /></div>
        <div className="gallery-content">
          Client: United Colors of Benetton
          {' '}
          <br />
          With UNITED COLORS OF BENETTON, we celebrated every yarn that unites us.
          Today, and for all days to come, we wrote under the campaign call may we be #UnitedByHope
        </div>
      </div>
      <div className="gallery-section">
        <div className="image-container"><img className="gallery-image" src={gallery3} alt="UCB" /></div>
        <div className="gallery-content">
          Client: United Colors of Benetton
          {' '}
          <br />
          With 50 dead and and over 53 injured at a gay club,
          the #OrlandoShootings make us question the extremes man
          can go to. And while we grieve in silence with our tongues
          tied in prayer, we unite to remember that when humanity
          joins hands, it’s always love that will triumph.
        </div>
      </div>
      <div className="gallery-section">
        <div className="image-container"><img className="gallery-image" src={gallery4} alt="Kohler" /></div>
        <div className="gallery-content">
          Client: Kohler
          {' '}
          <br />
          From allowing us to unleashing our inner singer to helping us get the best ideas, our bathrooms are spaces that let us be. With Kohler India, we penned tales on everything that can unfold in our home within home. #DreamInKohler at https://www.facebook.com/India.Kohler/
        </div>
      </div>
      <div className="gallery-section">
        <div className="image-container">
          <img className="gallery-image" src={gallery5} alt="Cornetto" />
        </div>
        <div className="gallery-content">
          Client: Cornetto
          {' '}
          <br />
          Love is universal, but also very, very individual.
          With @cornettoindia #ShowYourLove as you weave.
        </div>
      </div>
      <div className="gallery-section">
        <div className="image-container">
          <img className="gallery-image" src={gallery6} alt="Burger King" />
        </div>
        <div className="gallery-content">
          Client: Burger King
          {' '}
          <br />
          Got what it takes to #SurviveTheAngriest?
          We wrote to advertise sales for The Angriest Whopper, at your
          nearest Burger King outlet. May the fire be with you.
        </div>
      </div>
      <div className="gallery-section">
        <div className="image-container">
          <img className="gallery-image" src={gallery7} alt="Goodnight" />
        </div>
        <div className="gallery-content">
          Client: Goodnight
          {' '}
          <br />
          We’re celebrating Mother’s Weekend, and bringing you a heartful of
          laughs, tears, goose bumps and nostalgia.
        </div>
      </div>
      <div className="gallery-section">
        <div className="image-container">
          <img className="gallery-image" src={gallery8} alt="OneTouch" />
        </div>
        <div className="gallery-content">
          Client: One Touch
          {' '}
          <br />
          Families are pools. For glee, grief and their stories. They fill roofs with
          lessons and rooms with joy. With this brand, we dwelled in this sweetness.
          Because #FamilyMatters
          To safeguard these sweet moments from diabetes, visit: www.onetouchdiabetes.co.in
        </div>
      </div>
      <div className="gallery-section">
        <div className="image-container">
          <img className="gallery-image" src={gallery9} alt="OneTouch" />
        </div>
        <div className="gallery-content">
          Client: WaveNote
          {' '}
          <br />
          With this brand we were writing audio stories, to gather submissions
          for their ongoing spoken word contest and promote their newly launched application.
        </div>
      </div>
    </div>
  </div>
  // </>
);

export default Gallery;
