import React from 'react';
import './Publications.css';

const Publications = () => (
  <>
    {/* <div className="publication-banner-image" /> */}
    <div className="publications">
      <h2>Selected Publications</h2>
      <h3>Poetry</h3>
      <div className="pub-content">
        <a className="publication-link" href="https://www.honeyliterary.com/issue-8/poetry-another-failed-elegy-by-gauri-awasthi">“Another Failed Elegy”</a>
        {' '}
        in
        {' '}
        <i>
          Honey Literary (Issue 8)
        </i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://sundoglit.com/gauri-awasthi/">“Ghazal Split: Memory like Water”</a>
        {' '}
        in
        {' '}
        <i>
          Sundog Lit
        </i>
      </div>
      <div className="pub-content">
        {/* <a className="publication-link" href="https://bestnewpoets.org"> */}
        “Big Brown Boys”
        {/* </a> */}
        {' '}
        in
        {' '}
        <i>NELLE Magazine</i>
      </div>
      <div className="pub-content">
        {/* <a className="publication-link" href="https://bestnewpoets.org"> */}
        “Elegy for Living Mothers” and “Collected Vision”
        {/* </a> */}
        {' '}
        in
        {' '}
        <i>Epiphany Magazine</i>
      </div>
      <div className="pub-content">
        {/* <a className="publication-link" href="https://bestnewpoets.org"> */}
        “Self-Portrait with The Taj Mahal”
        {/* </a> */}
        {' '}
        in
        {' '}
        <i>Provincetown Arts Magazine</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://thepunchmagazine.com/the-byword/poetry/the-poetry-issue-2022-ghazal-after-babri-masjid-and-other-poems">“Ghazal After Babri Masjid” and other poems </a>
        {' '}
        in
        {' '}
        <i>Punch Magazine - Special Poetry Issue</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://www.quarterlywest.com/issue-104-awasthi">“The Mother Wound”</a>
        {' '}
        in
        {' '}
        <i>Quarterly West</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://ndreview.nd.edu/archived-issues/ndr-52/"> “Lotus-fishing at J.K. Temple” </a>
        {' '}
        in
        {' '}
        <i>Notre Dame Review</i>
        {' '}
        (Issue 52)
      </div>
      <br />
      <h3>Essays and Interviews</h3>
      <div className="pub-content">
        <a className="publication-link" href="https://dilettantearmy.com/articles/two-poets-looking-at-twenty-eight">
          Two Poets Looking: “At Twenty-Eight”, Critical Constellations Issue Edited by Yanyi
        </a>
        {' '}
        in
        {' '}
        <i>Dilettante Army</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://theoffingmag.com/interviews/qa-with-jess-yuan-author-of-slow-render/">“Q&A with Jess Yuan: Author of Slow Render”</a>
        {' '}
        in
        {' '}
        <i>The Offing</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://theoffingmag.com/interviews/qa-with-jacqui-germain-author-of-bittering-the-wound/">“Q&A with Jacqui Germain: Author of Bittering the Wound”</a>
        {' '}
        in
        {' '}
        <i>The Offing</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://theoffingmag.com/interviews/qa-with-saba-keramati-author-of-self-mythology/">“Q&A with Saba Keramati: Author of Self-Mythology”</a>
        {' '}
        in
        {' '}
        <i>The Offing</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://theoffingmag.com/interviews/qa-with-sarah-ghazal-ali-author-of-theophanies/">“Q&A with Sarah Ghazal Ali: Author of Theophanies”</a>
        {' '}
        in
        {' '}
        <i>The Offing</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://theoffingmag.com/interviews/qa-with-natasha-rao-author-of-latitude/">“Q&A with Natasha Rao, Author of Latitude”</a>
        {' '}
        in
        {' '}
        <i>The Offing</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://theoffingmag.com/interviews/qa-with-joshua-nguyen-author-of-come-clean/">“Mitski, Marie Kondo, and Coming Clean: Q&A with Joshua Nguyen”</a>
        {' '}
        in
        {' '}
        <i>The Offing</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://therumpus.net/2021/11/01/the-rumpus-interview-with-susan-nguyen/">“Meditations on Grief, Green and Childhood”, Interview</a>
        {' '}
        in
        {' '}
        <i>The Rumpus</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://livewire.thewire.in/gender-and-sexuality/how-the-indian-school-system-reinforces-gender-norms/">“How The Indian School System Reinforces Gender Norms”</a>
        {' '}
        in
        <i> The Wire </i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://livewire.thewire.in/politics/women-against-caa-nrc-breaking-the-patriarchy-from-within%E2%80%A8/">“Women Against CAA, NRC: Breaking the Patriarchy From Within”</a>
        {' '}
        in
        {' '}
        <i>The Wire</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://bestnewpoets.org">“Sixteen Beautiful Untranslatable Hindi Words You Didn’t Know You Needed”</a>
        {' '}
        in
        {' '}
        <i>Buzzfeed</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://www.filmcompanion.in/features/bollywood-features/psycho-shower-scene-netflix-78-52-alfred-hitchcock/">
          “Five Things We Learnt About Psycho’s Shower Scene”
        </a>
        {' '}
        in
        <i> Film Companion </i>
      </div>
      <br />
      <h3>
        You can buy books she was anthologized in here: (maybe don’t give money to
        terribly tiny tales though)
      </h3>
      <div className="pub-content">
        <a className="publication-link" href="https://bestnewpoets.org">“Partition Story”</a>
        {' '}
        in
        {' '}
        <i>Best New Poets, University of Virginia Press</i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://bestnewpoets.org">“Yearbook of Indian Poetry in English”</a>
        {' '}
        in
        {' '}
        <i>Pippa Ran UK</i>
      </div>
      <div className="pub-content">
        Three poems in
        {' '}
        <a className="publication-link" href="https://www.amazon.in/Ninety-Seven-Poems-Terribly-Tiny-Tales/dp/0143441175">“Ninety Seven Poems”</a>
        {' '}
        anthology published by
        <i> Penguin India </i>
      </div>
      <div className="pub-content">
        Three micro-fiction tales in the
        {' '}
        <a className="publication-link" href="https://www.amazon.com/Terribly-Tiny-Tales-Vol-I/dp/0143441167">“Terribly Tiny Tales”</a>
        {' '}
        anthology published by
        <i> Penguin India </i>
      </div>
      <br />
      <h3>
        You can listen to her share her story here:
      </h3>
      <div className="pub-content">
        <a className="publication-link" href="https://artscalling.com/2022/07/07/ep-52-gauri-awasthi-editorial-work-dance-and-community-in-poetry/">“Episode 52: Arts Calling Podcast”</a>
        ,
        {' '}
        <i>
          Apple and Spotify Podcasts
        </i>
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://www.jaredmccormack.com/mfawriters-gauri-awasthi">“Episode 49: MFA Writers Podcast”</a>
        ,
        {' '}
        <i>
          Apple and Spotify Podcasts
        </i>
      </div>
    </div>
  </>
);

export default Publications;
