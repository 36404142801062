import React from 'react';
import { withRouter } from 'react-router-dom';
import aboutImg1 from '../../assets/author.JPG';
import './About.css';

const About = ({ history }) => (
  <div className="about">
    <div className="ltr">
      <img className="about-img" src={aboutImg1} alt="gauri-awasthi" />
      <div className="about-content">
        <h2>
          Poet. Writer. Editor. Educator. Filmmaker.
        </h2>
        <div className="about-text">
          Gauri Awasthi, born and raised in Kanpur, India, received her MFA in creative
          writing from McNeese State University. She has won fellowships from
          {' '}
          <a className="about-link" href="https://yaddo.org/">
            <i>Yaddo,</i>
          </a>
          {' '}
          <a className="about-link" href="https://www.middlebury.edu/writers-conferences/writers-conference">
            <i>Bread Loaf Writers’ Conference,</i>
          </a>
          {' '}
          <a className="about-link" href="https://www.sundresspublications.com/safta">
            <i>Sundress Academy for The Arts,</i>
          </a>
          {' '}
          <a className="about-link" href="https://www.hedgebrook.org/">
            <i>Hedgebrook,</i>
          </a>
          {' '}
          <a className="about-link" href="https://communityofwriters.org/">
            <i>Community of Writers,</i>
          </a>
          {' '}
          and
          {' '}
          <a className="about-link" href="https://www.hambidge.org/">
            <i>Hambidge Center</i>
          </a>
          {' '}
          where she was a National Endowment For Arts Distinguished Fellow. She
          lives in New York City, and edits for
          {' '}
          <a className="about-link" href="https://theoffingmag.com/">
            <i>The Offing</i>
          </a>
          {' '}
          and
          {' '}
          <a className="about-link" href="https://elle.in/">
            <i>Elle India</i>
          </a>
          . Her research and creative writing pedagogy is focused on decolonizing poetic forms.
          <br />
          <br />
          A formally trained Bharatanatyam dancer, she also co-founded
          {' '}
          <a className="about-link" href="https://www.instagram.com/theveganwardrobe/">
            <i>The Vegan Wardrobe</i>
          </a>
          {' '}
          with her sister in 2016, to raise awareness about exploitation of
          craft and labor from the Global South in the fashion as well as beauty
          industry. She is reviving this project by building a virtual marketplace
          that will center handicrafts from India, supporting local artists, under
          the name
          {' '}
          <a className="about-link" href="https://duniya.co/">
            <i>“Duniya”</i>
          </a>
          {' '}
          – which means world in Hindi.
          <br />
          <br />
          You can read her published work
          {' '}
          <span
            className="about-link"
            onClick={() => history.push('/publications')}
            onKeyPress={() => history.push('/publications')}
            tabIndex={0}
            role="button"
          >
            <i>here</i>
          </span>
          .
        </div>
      </div>
    </div>
  </div>
);

export default withRouter(About);
