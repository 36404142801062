import React from 'react';
import './Films.css';

const Films = () => (
  <>
    {/* <div className="publication-banner-image" /> */}
    <div className="publications">
      <h2>Films</h2>
      <div className="formats-body">
        <div className="video-section">
          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/uSyf8wlsJVY" title="Mainawati" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
          </div>
          <div className="video-content">
            “Lotus Fishing at J.K. Temple”
            <br />
            Role: Writer and Director
            <br />
            Lotus Fishing - is a film guided by a poem I wrote, as an older woman speaker
            revisits her memory of visiting a temple with her grandfather by physically immersing
            herself in the space, only to find that so much has changed yet remained the
            same. In this narrative style of storytelling, you find a voice that shapes
            our earliest understanding of love. “Lotus Fishing at J.K. Temple” was a
            finalist at the Poetry Film Festival of Los Angeles, at the Theneulithium
            art gallery in Chicago, and was a semi-finalist at Best Short Fest in Ontario, Canada.
          </div>
        </div>
        <div className="video-section">
          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/kIUGhblAG0c" title="Mainawati" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
          </div>
          <div className="video-content">
            “Mainawati”
            <br />
            Role: Writer and Director
            <br />
            The story of a 13 year old Mainawati, daughter of Nana Saheb, who was
            instrumental in the first freedom struggle of 1857 in India. Part-oral
            history and part-academic research, this project is aimed at resurrecting
            the forgotten stories of the women before us. You can read more about her history
            {' '}
            <a href="https://homegrown.co.in/homegrown-creators/a-photoseries-immortalzing-the-noble-sacrifice-of-an-unsung-female-freedom-fighter">
              here
            </a>
            .
          </div>
        </div>
        <div className="video-section">
          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/yVZwrudPq5Q" title="Match | Short Film with Subtitles" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
          </div>
          <div className="video-content">
            “Match”
            <br />
            Role: Script Consultant
            <br />
            A young couple has decided to amicably part ways. During their last conversation
            together, they question the concept of “soulmates”.
          </div>
        </div>
        <div className="video-section">
          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/hiFIkCF6SGU" title="Sheher Ya Tum - Yahya Bootwala | Jidnya Sujata | MAMI 2018 Best Short Film" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
          </div>
          <div className="video-content">
            “Sheher Ya Tum” - Winner of MAMI film festival 2018
            <br />
            Role: Concept Writer and Story Building
            <br />
            A poet has been contemplating a very important life decision since the last two
            months. He has to choose between the city that has given him everything and the
            love of his life.
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Films;
